<template>
    <div id="contact" class="page-wrapper page-contact">
        <!-- Hero -->
        <header class="simple-hero site-max-width no-bg" hero-transition-group>
            <div class="hero-container container">
                <h1 class="hero-title title"><span v-html="contentIsLoaded ? content.heroSimple.titre : '&nbsp;'"></span></h1>
            </div>
        </header>

        <!-- Split Content -->
        <section class="split-content site-max-width no-space end" hero-transition-group>
            <div class="inner split-content-container">
                <div class="split-content-content">
                    <p class="split-content-text text">
                       <span v-html="contentIsLoaded ? content.texteSimple : '&nbsp;'"></span>
                    </p>
                </div>
                <div class="split-content-img" v-if="contentIsLoaded">
                    <img :src="content.imageSimple.url" :alt="content.imageSimple.titre" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'About',

    components: {
        // HelloWorld,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped>
    .page-wrapper {
        min-height: inherit;
        height: auto;
    }

    .split-content {
        padding-bottom: 4vw;
    }
</style>
